import React, { useState, useLayoutEffect, useRef, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Seo from "src/utils/Seo"
import Blocks from "../components/Blocks"
import { RichText } from "prismic-reactjs"
import Fade from "react-reveal/Fade"
import Img from "gatsby-image"
import linkResolver from "src/utils/linkResolver"
import GatsbyLink from "src/utils/GatsbyLink"

const ProgressBar = ({ prismicBlogPost, articleRef }) => {

  const { data } = prismicBlogPost

  // https://codedaily.io/tutorials/79/Create-an-Animated-Circular-Progress-Indicator-to-Track-Article-Read-Percentage-in-React
  const [progress, setProgress] = useState(0)

  useLayoutEffect(() => {
    const updateHeight = () => {
      if (!articleRef.current) return
      const { height } = articleRef.current.getBoundingClientRect()
      setProgress(window.scrollY / (height - window.innerHeight / 5))
    }

    updateHeight()

    window.addEventListener("scroll", updateHeight)
    return () => {
      window.removeEventListener("scroll", updateHeight)
    }
  })

  const position = Math.min(Math.max(progress * 100, 0), 100)

  // https://stackoverflow.com/questions/57453141/using-react-hooks-to-update-w-scroll
  const [scrolling, setScrolling] = useState(true)
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    let currentPosition = window.pageYOffset
    function onScroll() {
      currentPosition = window.pageYOffset
      if (window.scrollY > 200) {
        if (currentPosition > scrollTop) {
          setScrolling(false)
        } else {
          setScrolling(true)
        }
      }
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition)
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollTop])

  return (
    <div
      className={`sticky-nav bg-white fixed w-full z-40 transition-all duration-500 ease-in-out hidden xl:block  ${
        scrolling ? "-top-16" : "top-0"
      }`}
    >
      <div className="px-14 py-4 flex justify-between">
        <div className="block relative items-center">
          <Link
            to="/our-blog/"
            className="text-darkgrey font-bold text-xs tracking-widest uppercase mx-3 py-4 hover:text-gold duration-500 ease-in-out"
          >
            <i className="fas fa-chevron-left mr-2"></i>Back
          </Link>
        </div>
        <div className="reading">
          <span className="text-darkgrey font-bold text-xs tracking-widest uppercase mx-3 py-4">
            Reading:{" "}
          </span>{" "}
          <i>{data.title.text}</i>
        </div>
        <div className="social">
          <div className="text-left flex items-center">
            <h4 className="text-darkgrey font-bold text-xs tracking-widest uppercase mr-5">
              Share:{" "}
            </h4>
            <div className="space-x-4 text-base">
              <a
                href={`https://twitter.com/share?url=https://deuren.co.uk/inspiration/case-study${prismicBlogPost.url}`}
                target="_blank"
                rel="noreferrer"
              >
                <i
                  className="fab fa-twitter text-darkgrey hover:text-gold transition duration-300 ease-in-out"
                  aria-label="Twitter"
                ></i>
              </a>
              <a
                href={`http://www.facebook.com/sharer.php?u=https://deuren.co.uk/inspiration/case-study${prismicBlogPost.url}`}
                target="_blank"
                rel="noreferrer"
              >
                <i
                  className="fab fa-facebook text-darkgrey hover:text-gold transition duration-300 ease-in-out"
                  aria-label="Facebook"
                ></i>
              </a>
              <a
                href={`mailto:?subject=${data.title.text}&body=Check out this Case Study on Deuren: https://deuren.co.uk/inspiration/case-study${prismicBlogPost.url}`}
              >
                <i
                  className="far fa-envelope text-darkgrey hover:text-gold transition duration-300 ease-in-out"
                  aria-label="Email"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-0.5 bg-grey top-full absolute">
        <div
          className="test bg-gold h-full"
          Style={`width: ${position}%`}
        ></div>
      </div>
    </div>  
  )
}

const BlogPost = ({ data: { prismicBlogPost, allPrismicBlogPost } }, props) => {
  const { data } = prismicBlogPost
  const articleRef = useRef()

  const readMoreItems = allPrismicBlogPost.edges.map((item, index) => {
    return (
      <div className="md:flex w-full lg:w-1/2 pr-4 border-l border-gold">
        <Fade>
          <Link
            to={linkResolver(item.node)}
            key={index}
            title={`${item.node.data.title.text}`}
            className="group text-left block"
          >
            <Img
              fluid={item.node.data.featured_image.fluid}
              alt={item.node.data.title.text}
              className="-ml-px transition duration-500 ease-in-out group-hover:opacity-70 w-full h-0 pb-[60%]"
            />
            <div className="p-6 pb-0">
              <date className="text-darkgreysecond text-xs">
                {item.node.data.post_date} - {item.node.tags.join(", ")}
              </date>
              <h2 className="lg:h-8 text-darkgrey font-bold text-xs tracking-widest uppercase my-6 group-hover:text-gold duration-500 ease-in-out">
                {item.node.data.title.text}
              </h2>
              <RichText
                render={item.node.data.description.richText}
                serializeHyperlink={GatsbyLink}
              />
            </div>
          </Link>
        </Fade>
      </div>
    )
  })



  return (
    <article className="blog-post">
      <Seo
        title={data.meta_title.text ? data.meta_title.text : data.title.text}
        description={
          data.meta_description.text
            ? data.meta_description.text
            : data.description.text
        }
        image={data.meta_image?.url ? data.meta_image.url : data.featured_image?.url}
        article={true}
      />
      <ProgressBar prismicBlogPost={prismicBlogPost} articleRef={articleRef} />
      <div ref={articleRef} className={`bg-offwhite`}>
        <div className="container pb-0">
          <Fade>
            <Link
              to="/our-blog/"
              className="text-darkgrey font-bold text-xs tracking-widest uppercase hover:text-gold duration-500 ease-in-out"
            >
              <i className="fas fa-chevron-left mr-2"></i>Back
            </Link>
          </Fade>
          <div className="mt-12">
            <Fade>
              <date className="text-darkgreysecond text-xs">
                {data.post_date} - {prismicBlogPost.tags.join(", ")}
              </date>
            </Fade>
            <Fade>
              <h1 className="font-display text-gold rfs:text-5xl max-w-xl mt-6">
                {data.title.text}
              </h1>
              <div className="w-32 separator mt-5"></div>
            </Fade>
          </div>
        </div>
        <Blocks blocks={data.body} />
        <div className="text-center container">
          <Fade distance="20px" bottom>
            <h4 className="text-darkgrey font-bold text-xs tracking-widest uppercase mb-2">
              Share this
            </h4>
            <div className="space-x-4 rfs:text-xl">
              <a
                href={`https://twitter.com/share?url=https://deuren.co.uk/inspiration/case-study${prismicBlogPost.uid}`}
                target="_blank"
                rel="noreferrer"
              >
                <i
                  className="fab fa-twitter text-darkgrey hover:text-gold transition duration-300 ease-in-out"
                  aria-label="Twitter"
                ></i>
              </a>
              <a
                href={`http://www.facebook.com/sharer.php?u=https://deuren.co.uk/inspiration/case-study${prismicBlogPost.uid}`}
                target="_blank"
                rel="noreferrer"
              >
                <i
                  className="fab fa-facebook text-darkgrey hover:text-gold transition duration-300 ease-in-out"
                  aria-label="Facebook"
                ></i>
              </a>
              <a
                href={`mailto:?subject=${data.title.text}&body=Check out this Case Study on Deuren: https://deuren.co.uk/inspiration/case-study${prismicBlogPost.uid}`}
              >
                <i
                  className="far fa-envelope text-darkgrey hover:text-gold transition duration-300 ease-in-out"
                  aria-label="Email"
                ></i>
              </a>
            </div>
          </Fade>
        </div>
      </div>
      <section className="bg-verylightgrey">
        <div className="container text-center">
          <Fade>
            <h2 className="font-display text-gold rfs:text-5xl">
              More Articles
            </h2>
            <div className="w-12 separator mt-5"></div>
          </Fade>
          <div className="mt-16 flex flex-row justify-center flex-wrap lg:space-y-0 space-y-12">
            {readMoreItems}
          </div>
        </div>
      </section>
    </article>
  )
}

export default BlogPost

export const BlogPostQuery = graphql`
  query BlogPostBySlug($uid: String!, $uidd: [String]) {
    prismicBlogPost(uid: { eq: $uid }) {
      uid
      tags
      data {
        post_date(formatString: "DD MMM YYYY")
        title {
          text
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_image {
          url
        }
        featured_image {
          url
          alt
        }
        description {
          richText
          text
        }
        body {
          ... on PrismicBlogPostDataBodyImage {
            slice_type
            primary {
              image {
                url
                alt
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicBlogPostDataBodyContent {
            slice_type
            primary {
              content {
                richText
              }
              title1 {
                text
              }
            }
          }
        }
      }
    }
    allPrismicBlogPost(limit: 2, filter: { uid: { nin: $uidd } }) {
      edges {
        node {
          uid
          type
          tags
          data {
            post_date(formatString: "DD MMM YYYY")
            title {
              text
            }
            featured_image {
              url
              alt
              fluid(srcSetBreakpoints: [400, 800]) {
                srcSet
              }
            }
            description {
              richText
            }
          }
        }
      }
    }
  }
`
